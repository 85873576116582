import axios from '@/common/axios'
import qs from 'qs'

export function addOfficialActivities (data) {
  return axios({
    method: 'post',
    url: '/officialActivities/add',
    data: qs.stringify(data)
  })
}

export function deleteOfficialActivities (id) {
  return axios({
    method: 'delete',
    url: '/officialActivities/delete/' + id
  })
}

export function updateOfficialActivities (data) {
  return axios({
    method: 'put',
    url: '/officialActivities/update',
    data: qs.stringify(data)
  })
}

export function selectOfficialActivitiesInfo (id) {
  return axios({
    method: 'get',
    url: '/officialActivities/info/' + id
  })
}

export function selectOfficialActivitiesList (query) {
  return axios({
    method: 'get',
    url: '/officialActivities/list',
    params: query
  })
}
