<template>
  <div id="officialActivities">
    <el-dialog
      :title="officialActivitiesFormTitle"
      width="450px"
      :visible.sync="officialActivitiesDialogVisible"
      :close-on-click-modal="false"
      @close="officialActivitiesDialogClose"
    >
      <el-form
        ref="officialActivitiesFormRef"
        :model="officialActivitiesForm"
        :rules="officialActivitiesFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="活动名称" prop="name">
              <el-input v-model="officialActivitiesForm.name" placeholder="请输入活动名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="活动描述" prop="description">
              <el-input v-model="officialActivitiesForm.description" placeholder="请输入活动描述" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="图片" prop="pic">
              <el-upload
                ref="uploadRef1"
                action=""
                list-type="picture-card"
                :http-request="handleHttp1"
                :on-remove="handleRemove1"
                :on-preview="handlePreview1"
                :file-list="fileList1"
                :limit="1"
              >
                <i class="el-icon-plus" />
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="封面" prop="cover">
              <el-upload
                ref="uploadRef2"
                action=""
                list-type="picture-card"
                :http-request="handleHttp2"
                :on-remove="handleRemove2"
                :on-preview="handlePreview2"
                :file-list="fileList2"
                :limit="1"
              >
                <i class="el-icon-plus" />
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="是否显示" prop="isShow">
              <el-radio-group v-model="officialActivitiesForm.isShow">
                <el-radio :label="1">
                  显示
                </el-radio>
                <el-radio :label="0">
                  不显示
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="officialActivitiesDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="officialActivitiesFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-dialog id="image" width="540px" :visible.sync="imageDialogVisible">
      <img
        width="500px"
        object-fit="contain"
        :src="imageUrl"
        alt=""
      >
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="活动名称">
        <el-input v-model="searchForm.name" placeholder="请输入活动名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="officialActivitiesPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="id" label="活动id" />
      <el-table-column prop="name" label="活动名称" />
      <el-table-column prop="description" label="活动描述" />
      <el-table-column prop="pic" label="图片">
        <template slot-scope="scope">
          <el-image
            v-if="scope.row.pic"
            style="width: 100px; height: 100px"
            fit="contain"
            :src="baseUrl + scope.row.pic"
            :preview-src-list="[baseUrl + scope.row.pic]"
          />
        </template>
      </el-table-column>
      <el-table-column prop="cover" label="封面">
        <template slot-scope="scope">
          <el-image
            v-if="scope.row.cover"
            style="width: 100px; height: 100px"
            fit="contain"
            :src="baseUrl + scope.row.cover"
            :preview-src-list="[baseUrl + scope.row.cover]"
          />
        </template>
      </el-table-column>
      <el-table-column label="是否显示">
        <template slot-scope="scope">
          <span v-if="scope.row.isShow === 0">不显示</span>
          <span v-if="scope.row.isShow === 1">显示</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="officialActivitiesPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addOfficialActivities,
  deleteOfficialActivities,
  updateOfficialActivities,
  selectOfficialActivitiesInfo,
  selectOfficialActivitiesList
} from '@/api/main/officialActivities'
import { upload2 } from '@/api/main/file'

export default {
  data () {
    return {
      officialActivitiesDialogVisible: false,
      officialActivitiesFormTitle: '',
      officialActivitiesForm: {
        id: '',
        name: '',
        description: '',
        pic: '',
        cover: '',
        isShow: ''
      },
      officialActivitiesFormRules: {
        name: [{ required: true, message: '活动名称不能为空', trigger: ['blur', 'change']}],
        description: [{ required: true, message: '活动描述不能为空', trigger: ['blur', 'change']}],
        isShow: [{ required: true, message: '是否显示不能为空', trigger: ['blur', 'change']}]
      },
      officialActivitiesPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        name: ''
      },
      baseUrl: 'https://oss-quye.huahuihongxin.com/',
      imageDialogVisible: false,
      imageUrl: '',
      fileList1: [],
      fileList2: []
    }
  },
  created () {
    selectOfficialActivitiesList(this.searchForm).then(res => {
      this.officialActivitiesPage = res
    })
  },
  methods: {
    officialActivitiesDialogClose () {
      this.$refs.officialActivitiesFormRef.resetFields()
      this.fileList1 = []
      this.fileList2 = []
    },
    officialActivitiesFormSubmit () {
      if (this.officialActivitiesFormTitle === '官方活动详情') {
        this.officialActivitiesDialogVisible = false
        return
      }
      this.$refs.officialActivitiesFormRef.validate(async valid => {
        if (valid) {
          this.officialActivitiesForm.pic = this.fileList1.map(item => item.name).join(',')
          this.officialActivitiesForm.cover = this.fileList2.map(item => item.name).join(',')
          if (this.officialActivitiesFormTitle === '新增官方活动') {
            await addOfficialActivities(this.officialActivitiesForm)
          } else if (this.officialActivitiesFormTitle === '修改官方活动') {
            await updateOfficialActivities(this.officialActivitiesForm)
          }
          this.officialActivitiesPage = await selectOfficialActivitiesList(this.searchForm)
          this.officialActivitiesDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.officialActivitiesFormTitle = '新增官方活动'
      this.officialActivitiesDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteOfficialActivities(row.id)
        if (this.officialActivitiesPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.officialActivitiesPage = await selectOfficialActivitiesList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.officialActivitiesFormTitle = '修改官方活动'
      this.officialActivitiesDialogVisible = true
      this.selectOfficialActivitiesInfoById(row.id)
    },
    handleInfo (index, row) {
      this.officialActivitiesFormTitle = '官方活动详情'
      this.officialActivitiesDialogVisible = true
      this.selectOfficialActivitiesInfoById(row.id)
    },
    selectOfficialActivitiesInfoById (id) {
      selectOfficialActivitiesInfo(id).then(res => {
        this.officialActivitiesForm.id = res.id
        this.officialActivitiesForm.name = res.name
        this.officialActivitiesForm.description = res.description
        if (res.pic) {
          const picUrl = res.pic.split(',')
          this.fileList1 = []
          for (let i = 0; i < picUrl.length; i++) {
            this.fileList1.push({
              name: picUrl[i],
              url: this.baseUrl + picUrl[i]
            })
          }
        }
        if (res.cover) {
          const cover = res.cover.split(',')
          this.fileList2 = []
          for (let i = 0; i < cover.length; i++) {
            this.fileList2.push({
              name: cover[i],
              url: this.baseUrl + cover[i]
            })
          }
        }
        this.officialActivitiesForm.isShow = res.isShow
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectOfficialActivitiesList(this.searchForm).then(res => {
        this.officialActivitiesPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectOfficialActivitiesList(this.searchForm).then(res => {
        this.officialActivitiesPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectOfficialActivitiesList(this.searchForm).then(res => {
        this.officialActivitiesPage = res
      })
    },
    handleHttp1 (file) {
      const data = new FormData()
      data.append('file', file.file)
      upload2(data).then(res => {
        this.fileList1.push({
          name: res,
          url: this.baseUrl + res,
          uid: file.file.uid
        })
      })
    },
    handleRemove1 (file, fileList) {
      this.fileList1 = fileList
    },
    handlePreview1 (file) {
      this.imageUrl = file.url
      this.imageDialogVisible = true
    },
    handleHttp2 (file) {
      const data = new FormData()
      data.append('file', file.file)
      upload2(data).then(res => {
        this.fileList2.push({
          name: res,
          url: this.baseUrl + res,
          uid: file.file.uid
        })
      })
    },
    handleRemove2 (file, fileList) {
      this.fileList2 = fileList
    },
    handlePreview2 (file) {
      this.imageUrl = file.url
      this.imageDialogVisible = true
    }
  }
}
</script>

<style>
#officialActivities #image .el-dialog__body {
  padding: 20px;
}
</style>
